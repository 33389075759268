//Objeto de la pagina de metanima

const data = {
  titulo: "Soñar es crear",
  estudio: "Estudio de animacion",
  parrafo: `
  Nuestro objetivo es proporcionar a los estudiantes un conjunto integral de recursos didácticos de alta calidad que respalden su proceso de aprendizaje y práctica en el emocionante ámbito de la animación. A través de una cuidadosa selección de contenido, desde tutoriales interactivos hasta ejercicios prácticos, aspiramos a empoderar a los estudiantes en su camino hacia la mejora de sus habilidades. Al facilitar un acceso fácil y estructurado a información relevante y actualizada, deseamos fomentar la autonomía del aprendizaje y la creatividad individual. Nuestra meta es contribuir al crecimiento constante de los estudiantes en este campo, preparándose para enfrentar desafíos y oportunidades en la industria de manera competente y segura.`,
  novedades: [
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento", // dia mes anio
    },
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento",
    },
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento",
    },
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento",
    },
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento",
    },
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento",
    },
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento",
    },
    {
      img: "img15.png",
      nombre: "nombre completo de la noticia",
      fecha: "Fecha de lanzamiento",
    },
  ],

  //secciones
  secciones: [
    {
      // seccion se manda por el titulo
      contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img1: "img15.png",
      urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

      contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img2: "img15.png",
      urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
    },
    {
      // seccion se manda por el titulo
      contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img1: "img15.png",
      urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

      contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img2: "img15.png",
      urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
    },
    {
      // seccion se manda por el titulo
      contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img1: "img15.png",
      urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

      contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img2: "img15.png",
      urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
    },
    {
      // seccion se manda por el titulo
      contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img1: "img15.png",
      urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

      contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
      img2: "img15.png",
      urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
    },
  ],

  seccioness: [
    {
      titulo: "seccion I",
      seccion: [
        {
          titulo: "seccion Ia",
          contenido1: `la Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion Ia2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion Ib",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion Ib2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion Ic",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion Ic2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion Id",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion Id2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
      ],
    },
    {
      titulo: "seccion II",
      seccion: [
        {
          // seccion se manda por el titulo
          titulo: "seccion IIa",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IIa2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IIb",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IIb2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IIc",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IIc2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IId",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IId2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
      ],
    },
    {
      titulo: "seccion III",
      seccion: [
        {
          // seccion se manda por el titulo
          titulo: "seccion IIIa",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IIIa2",
          contenido2: `Lorem ipum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IIIb",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IIIb2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IIIc",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IIIc2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IIId",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IIId2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
      ],
    },
    {
      titulo: "seccion IV",
      seccion: [
        {
          // seccion se manda por el titulo
          titulo: "seccion IVa",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IVa2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IVb",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IVb2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo: "seccion IVc",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IVc2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
          // seccion se manda por el titulo
          titulo1: "seccion IVd",
          contenido1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img1: "img15.png",
          urlIframe1: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

          titulo2: "seccion IVd2",
          contenido2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              ipsum dolor sit amet, consectetur`,
          img2: "img15.png",
          urlIframe2: `https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
      ],
    },
  ],
};

export { data };
