// objeto de pagina de queines somos

const data = {
    cisfarm:"CISFARM",
    titulo:"trae tecnología y salud a tu alcance.",

    parrafo:`Bienvenidos a Cisfarm, donde la medicina y la tecnología convergen. Ofrecemos educación de vanguardia en el campo de la farmacología profesional, integrando habilidades tecnológicas para formar expertos innovadores. Prepárate para liderar la revolución médica con conocimientos sólidos y herramientas tecnológicas avanzadas en Cisfarm`,
    
    //nuestra historia
 
    historia:[
        {
            dia:"dia",
            mes:"mes",
            año:2023,
            parrafo:`rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,`
        },
        {
            dia:"dia",
            mes:"mes",
            año:2023,
            parrafo:`rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,`
        },
        {
            dia:"dia",
            mes:"mes",
            año:2023,
            parrafo:`rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,`
        },
        {
            dia:"dia",
            mes:"mes",
            año:2023,
            parrafo:`rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,`
        },
        {
            dia:"dia",
            mes:"mes",
            año:2023,
            parrafo:`rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,`
        },
    ],
    promesa:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    objetivo:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,

    mision:[
        {item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"},
        {item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"},
        {item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"},
        {item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"},
    ],

    vision:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum.`,


    // staff

    staff:[
        {
            imagen:"sin_back.png",
            cargo:"CEO",
            nombres:"Nombres",
            apellidos:"Apellidos",
            parrafo:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. `
        },
        {
            imagen:"sin_back.png",
            cargo:"COO",
            nombres:"Nombre",
            apellidos:"Apellidos",
            parrafo:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. `
        },
        {
            imagen:"sin_back.png",
            cargo:"Coordinador",
            nombres:"Nombre",
            apellidos:"Apellidos",
            parrafo:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. `
        }
    ],


    //areas

    areas:[
        {
            area:"nombre de area1",
            cards:[
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                },
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                }
            ]
        },
        {
            area:"nombre de area1",
            cards:[
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                },
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                }
            ]
        }
    ]

    


};

export {data};