// Menu-inicio

import React from "react";
import "./nav.css";
import Footer from "./Footer";
import Casos from "./Casos";
import Bord from "./Bord";
import CardSlider from "./CardSlider";
import Header from "./Header";
import TituloInicio from "./TituloInicio";
import Footer2 from "./Footer2";
import background from '../assets/imagenes/back_inicio.png';
import { Fade } from "react-reveal";
import {data} from '../objetos/ObjetoInicio';
import Flotante from "./Flotante";


function Inicio() {
  



  return (
    <>
      <div className="" style={{backgroundImage:`url(${background})`}}>
        <Header/>
        <Fade>
        <TituloInicio datos={data[0]}/>

        

        <CardSlider datos={data[0]} className="my-3"/>
        
        <Casos datos={data[0]}/>
       
       
        <Bord datos={data[0]}/>
        <Flotante/>
        

        <Footer />
        <div style={{backgroundColor:"#DEECFA"}}>
        <Footer2/>

        </div>
        </Fade>
        </div>
        

         
      
    
    </>
  );
}

export default Inicio;
