//objeto de pagina de blogs

const data={
    noticias:[
      {
        img: 'img16.png',
        titulo: "titulo de la noticia",
        contenido: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Lorem
        ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat.Lorem
        ipsum`,
      },
      {
        img: 'img16.png',
        titulo: "Titulo de la noticia",
        contenido: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Lorem
        ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat.Lorem
        ipsum`,
      },
      {
        img: 'img16.png',
        titulo: "Titulo de la noticia",
        contenido: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Lorem
        ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat.Lorem
        ipsum`,
      },
    ],
    posts:[
        {
            img: 'img16.png',
            nombre: "nombre completo de la noticia posts1",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia2",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia3",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia4",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia5",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia6",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia7",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia8",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia9",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
    ],
    sabiasPosts:[
        {
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias1",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias2",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias3",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias4",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias5",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias6",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias7",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          }
    ]

};

export {data};