
// Objeto de la pagina de inicio
const data = [{
    //parte de los titulos
    titulo: "Prepárate en nuestro programas de entrenamiento",
    parrafo:`Si deseas información personalizada sobre nuestro eventos, regístrate y te contactaremos con nuestros asesores.`,
    boton:"Registrarse",

    // Parte de eventos

    eventos:[
        {imagen:'2.png'},
        {imagen:'2.png'},
        {imagen:'3.png'},
        {imagen:'4.png'},
        {imagen:'5.png'},
        {imagen:'6.png'},
    ],

    //casos de exito

    casos:[
        {
            texto:`Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada.  `,
            parrafo:`Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
            imagen:"Alicia Esmeralda Triviños Ancachi.png",
            puesto:"1",
            diminutivo:"er",
            nombres:"Nombres",
            apellidos:"Apellidos",
            especialidad:"Quimico Farmaceutico"
        },
        {
          texto:`Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada.  `,
          parrafo:`¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
            imagen:"Robert Anthony Dietz Maravi.png",
            puesto:"1",
            diminutivo:"er",
            nombres:"Nombres",
            apellidos:"Apellidos",
            especialidad:"Quimico Farmaceutico"
        },
        {
          texto:`Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada.  `,
          parrafo:`¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
            imagen:"Ruth Jenny Lucas Vargas.png",
            puesto:"1",
            diminutivo:"er",
            nombres:"Nombres",
            apellidos:"Apellidos",
            especialidad:"Quimico Farmaceutico"
        },
        
    ],

    //tarjetas
    bord:[{
        imagen:'Christopher_Davila Mendoza.png',
        titulo: "Asesor Academico",
        nombre: "Alvaro Jose",
        apellido: "Andrade Condori",
        puesto: "Quimico Farmaceutico",
        orden: "1 Puesto",
        lugar: " Farmacia Hospitalaria",
      },
      {
        imagen: 'David_James Vásquez Díaz.png',
        titulo: "Asesor Academico",
        nombre: "Alvaro Jose",
        apellido: "Andrade Condori",
        puesto: "Quimico Farmaceutico",
        orden: "1 Puesto",
        lugar: " Farmacia Hospitalaria",
      },
      {
        imagen: 'Guido_Alonso Saldón Beltran.png',
        titulo: "Asesor Academico",
        nombre: "Alvaro Jose",
        apellido: "Andrade Condori",
        puesto: "Quimico Farmaceutico",
        orden: "1 Puesto",
        lugar: " Farmacia Hospitalaria",
      },
      {
        imagen: 'Mario_Cavero Medina.png',
        titulo: "Asesor Academico",
        nombre: "Alvaro Jose",
        apellido: "Andrade Condori",
        puesto: "Quimico Farmaceutico",
        orden: "1 Puesto",
        lugar: " Farmacia Hospitalaria",
      },]
}];

export {data};